<template>
  <div>
    <div class="row Aff-TestAnalysisReport-Header head" :key="componentKey">
      <div class="col s12 m10">
        <strong>
          <span>
            <a v-if="pageName === 'User'" style="color: #642c90">Edit User</a>
            <a v-else-if="pageName === 'CreateUser'" style="color: #642c90">Create User</a>
            <a v-else style="color: #642c90">Create User</a>
          </span>
        </strong>
      </div>
    </div>
    <hr />
    <!-- <div class="divider divide hide-on-small-only"></div> -->
    <div class="coursecard card-panel">
      <div class="row">
        <div class="col s12 m6">
          <label class="dtitle">UserId :</label>
          {{ CreateUser.UserId }}
        </div>
        <div class="col s12 m6 right-align" style="text-decoration: underline">
          <label class="dtitle" style="color: #6a2f85">Owner Account :</label>
          <span>
            <strong>
              {{ this.OwnerName }}
            </strong>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col s12 m4">
          <label class="dtitle">Name<span class="required-field"></span></label>
          <input
            class=""
            type="text"
            :disabled="AvailableLogins <= 0"
            @change="Result()"
            placeholder="Please enter your Name here"
            v-model="CreateUser.Name"
          />
          <span v-if="wrongname" style="color: red">Please Enter Name</span>
        </div>
        <div class="col s12 m4">
          <label class="dtitle">Email Id<span class="required-field"></span></label>
          <input
            class=""
            v-model="CreateUser.EmailId"
            type="email"
            :disabled="AvailableLogins <= 0"
            placeholder="Email-Id"
            :readonly="pageName === 'User'"
            @change="Result()"
          />
          <span v-show="wrongEmail" style="color: red">Incorrect email address</span>
        </div>
        <div class="col s12 m4">
          <label class="dtitle">Mobile</label>
          <input
            class="validate"
            v-model="CreateUser.Mobile"
            type="tel"
            :disabled="AvailableLogins <= 0"
            placeholder="Mobile"
            @change="isNumberValid"
          />
        </div>
      </div>

      <div class="row">
        <div class="col s12 m4">
          <label class="dtitle">Gender</label>
          <div class="live-class-radio">
            <label class="radio" style="color: black">
              <input
                class="with-gap"
                name="CreateUser.Gender"
                type="radio"
                :disabled="AvailableLogins <= 0"
                v-model="CreateUser.Gender"
                @change="Result()"
                value="1"
                checked
              />
              <span>Male</span>
            </label>
            &#9;
            <label class="radio" style="color: black">
              <input
                class="with-gap"
                name="CreateUser.Gender"
                type="radio"
                :disabled="AvailableLogins <= 0"
                v-model="CreateUser.Gender"
                @change="Result()"
                value="2"
              />
              <span>Female</span>
            </label>
          </div>
        </div>
        <div class="col s12 m4">
          <div>
            <label class="dtitle">Date of Birth</label>
          </div>
          <input
            v-model="CreateUser.DOB"
            class="browser-default dropdDate"
            type="date"
            :disabled="AvailableLogins <= 0"
          />
        </div>
        <div class="col s12 m4">
          <label class="dtitle">Role<span class="required-field"></span></label>
          <select
            class="browser-default dropd"
            :disabled="AvailableLogins <= 0"
            v-model="CreateUser.RoleId"
            @change="Result()"
          >
            <option value="" disabled selected>-- Select Role --</option>
            <option v-for="roles in role" v-bind="{ value: roles.roleId }" :key="roles.roleId">
              {{ roles.roleName }}
            </option>
          </select>
          <div v-if="wrongrole" class="col-md-6 col-sm-12" style="color: red">
            Please select role
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s12 m4">
          <label class="dtitle">Course<span class="required-field"></span></label>
          <select
            class="browser-default dropd"
            @change="
              Result();
              onCourseChange($event);
            "
            v-model="CreateUser.CourseId"
            :disabled="AvailableLogins <= 0"
          >
            <option value="" disabled selected>-- select --</option>
            <option :value="data.courseId" v-for="data in course" :key="data.affiliationCourseId">
              {{ data.courseName }}
            </option>
          </select>
          <div v-if="wrongcourse" class="col-md-6 col-sm-12" style="color: red">
            Please select course
          </div>
        </div>
        <div class="col s12 m4">
          <label class="dtitle">Batch<span class="required-field"></span></label>

          <select
            class="browser-default dropd"
            @change="
              Result();
              onBatchChange($event);
            "
            v-model="CreateUser.CenterCodeId"
            :disabled="AvailableLogins <= 0"
          >
            <option value="" disabled selected>-- select --</option>
            <option
              :value="batchitems.BatchId"
              v-for="batchitems in courseBatchList"
              :key="batchitems.BatchId"
            >
              {{ batchitems.BatchName }}
            </option>
          </select>
          <div v-if="wrongbatch" class="col-md-6 col-sm-12" style="color: red">
            Please select batch
          </div>
        </div>
        <div class="col s8 m3">
          <label class="dtitle">Subject</label>
          <VueMultiselect
            class="browser-default dropd"
            v-model="subjectSelected"
            :disabled="AvailableLogins <= 0"
            tag-placeholder="Add this Subject"
            placeholder="Search Or Add subject"
            label="subjectName"
            track-by="subjectId"
            :options="SelectedSubjectList"
            :multiple="true"
            :taggable="true"
            @tag="addTag"
            :limit="1"
          >
            <template v-slot:beforeList>
              <div>
                <button @click="selectNone">none</button>
                <button @click="selectAll">all</button>
              </div>
            </template>
          </VueMultiselect>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col s12 m4">
          <label class="dtitle">Select Session<span class="required-field"></span></label>
          <div class="col-md-7 col-sm-12">
            <select class="browser-default dropd" :disabled="this.AvailableLogins <= 0"
              v-model="CreateUser.BatchId" @change="Result()">
              <option value="" disabled selected>-- Select Session --</option>
              <option v-for="selectsession in sessions" v-bind="{ value: selectsession.batchId }"
                :key="selectsession.id">
                {{ selectsession.batchName }}
              </option>
            </select>
          </div>
          <div v-if="wrongsession" class="col-md-6 col-sm-12" style="color: red">
            Please select session
          </div>
        </div> -->
        <div class="col s12 m4">
          <div class="bcontent">
            <label class="dtitle"> Account expiry date<span class="required-field"></span> </label>
            <input
              :disabled="AvailableLogins <= 0"
              v-model="CreateUser.ExpiredOn"
              :max="maxDate"
              class="browser-default dropdDate"
              @change="Result()"
              type="date"
            />
          </div>
          <div v-if="accountexpiry" class="col-md-6 col-sm-12" style="color: red">
            Please select Account Expiry Date
          </div>
        </div>
      </div>

      <div class="row">
        <!-- <div class="col s12 m4">
          <strong>
            <label>
              <input type="checkbox" class="filled-in" id="NTA"
                v-model="CreateUser.IsThirdPartyTestEnabled" :disabled="this.AvailableLogins <= 0"
                checked="checked" />
              <span>Is NTA Test enabled?</span>
            </label></strong>
        </div> -->
        <div class="col s12 m4">
          <strong>
            <label>
              <input
                type="checkbox"
                class="filled-in"
                id="IsActive"
                v-model="CreateUser.isActive"
                :disabled="AvailableLogins <= 0"
                checked="checked"
              />
              <span>Is Active</span>
            </label></strong
          >
        </div>
      </div>
      <div class="row">
        <div class="col s12 m4">
          <button
            v-if="pageName === 'User'"
            class="dbtn waves-effect waves-light btn getclassbutton"
            :disabled="AvailableLogins <= 0"
            type="button"
            @click="updateuser()"
          >
            Update User
          </button>
          <button
            v-else
            class="dbtn waves-effect waves-light btn getclassbutton"
            :disabled="AvailableLogins <= 0"
            type="button"
            @click="createuser()"
          >
            Create User
          </button>
        </div>
      </div>
    </div>

    <div v-if="pageName !== 'User'" class="coursecard card-panel">
      <div class="row" style="justify-content: space-evenly">
        <div class="col s12 m5">
          <div class="bulktxt" style="font-size: 27px; color: #642c90">
            <strong>Upload File for Bulk User</strong>
          </div>
          <div style="font-size: 20px">
            Download:<a @click="GetAffiliationSampleReport()"
              ><strong style="font-size: 18px; text-decoration: underline"> SampleFile</strong></a
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col s12 m4">
          <label class="dtitle">Course<span class="required-field"></span></label>
          <select
            class="browser-default dropd"
            @change="onBulkUserCourseChange($event)"
            v-model="BulkUser.BulkCourseId"
            :disabled="AvailableLogins <= 0"
          >
            <option value="" disabled selected>-- select --</option>
            <option :value="data.courseId" v-for="data in course" :key="data.affiliationCourseId">
              {{ data.courseName }}
            </option>
          </select>
          <div v-if="wrongcourse" class="col-md-6 col-sm-12" style="color: red">
            Please select course
          </div>
        </div>
        <div class="col s12 m4">
          <label class="dtitle">Batch<span class="required-field"></span></label>

          <select
            class="browser-default dropd"
            @change="onBulkUserBatchChange($event)"
            v-model="BulkUser.BulkCenterCodeId"
            :disabled="AvailableLogins <= 0"
          >
            <option value="" disabled selected>-- select --</option>
            <option
              :value="batchitems.BatchId"
              v-for="batchitems in courseBatchList"
              :key="batchitems.BatchId"
            >
              {{ batchitems.BatchName }}
            </option>
          </select>
          <div v-if="wrongbatch" class="col-md-6 col-sm-12" style="color: red">
            Please select batch
          </div>
        </div>

        <div class="col s12 m4" style="margin-bottom: 10px">
          <label class="dtitle">Upload File<span class="required-field"></span></label>
          <input
            id="file1"
            :disabled="AvailableLogins <= 0"
            class="form-control ml-3"
            type="file"
            @change="handleFileUpload($event)"
          />
        </div>
      </div>
      <div class="row">
        <div class="col s12 m3">
          <button
            v-on:click="submitFile($event)"
            class="dbtn waves-effect waves-light btn getclassbutton"
            :disabled="AvailableLogins <= 0"
          >
            Create Bulk User
          </button>
        </div>
      </div>
      <div class="row" v-if="this.bulkUserUploadResult != ''">
        <div class="displayNone" id="BulkUserResult">
          <div>
            <strong>{{ calculateSuccessCount }} records successfully created.</strong>
          </div>
          <div>
            <strong>{{ calculateFailureCount }} records failed.</strong>
          </div>
          <table class="qtl">
            <thead>
              <tr>
                <th class="center-align">Status</th>
                <th class="center-align">EmailId</th>
                <th class="center-align">Name</th>
                <th class="center-align">Mobile</th>
                <th class="center-align">GenderId</th>
                <th class="center-align">Affiliation CodeId</th>
                <th class="center-align">CenterCodeId</th>
                <th class="center-align">RoleId</th>
                <th class="center-align">CourseId</th>
                <th class="center-align">BatchId</th>
                <th class="center-align">IsThirdParty TestEnabled</th>
                <th class="center-align">IsVideo TabDisplay</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in bulkUserUploadResult" :key="user.emailId">
                <td class="left-align" style="text-decoration: underline">
                  {{ user.addUserStatus }}
                </td>
                <td class="center-align">{{ user.emailId }}</td>
                <td class="center-align">{{ user.name }}</td>
                <td class="center-align">{{ user.mobile }}</td>
                <td class="center-align">
                  {{ user.gender === 1 ? "Male" : "Female" }}
                </td>
                <td class="center-align">{{ user.affiliationCodeId }}</td>
                <td class="center-align">{{ user.centerCodeId }}</td>
                <td class="center-align">{{ user.roleId }}</td>
                <td class="center-align">{{ user.courseId }}</td>
                <td class="center-align">{{ user.batchId }}</td>
                <td class="center-align">{{ user.isThirdPartyTestEnabled }}</td>
                <td class="center-align">{{ user.isVideoTabDisplay }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import $ from "jquery";
import Papa from "papaparse";
import moment from "moment";
import API from "../Api";
import Doubtapi from "../Doubtapi";
import Mobileapi from "../Mobileapi";

export default {
  name: "AffiliationCreateUser",
  components: {
    VueMultiselect,
  },
  data() {
    return {
      CreateUser: {
        AffiliationCodeId: "",
        UserCode: "",
        CenterCodeId: "",
        EmailId: "",
        Name: "",
        Mobile: "",
        Gender: "1",
        DOB: "",
        CourseId: "",
        AccessSubjectId: "",
        RoleId: 2,
        OwnerUserId: "",
        IsThirdPartyTestEnabled: true,
        isActive: true,
        ExpiredOn: "",
      },
      BulkUser: {
        BulkAffiliationCodeId: "",
        BulkCenterCodeId: "",
        BulkCourseId: "",
        BulkRoleId: 2,
      },
      OwnerName: "",
      AffiliationCourseId: null,
      sessions: [],
      selectCourse: -1,
      selectBatch: -1,
      subjectSelected: [],
      SelectedSubjectList: [],
      batchlist: [],
      wrongname: false,
      male: false,
      female: false,
      wrongEmail: false,
      wrongNumber: false,
      wrongcourse: false,
      wrongbatch: false,
      wrongsubject: false,
      wrongrole: false,
      noLogins: false,
      accountexpiry: false,
      course: [],
      courseBatchList: [],
      batch: [],
      role: [
        {
          roleId: 2,
          roleName: "Student",
        },
        {
          roleId: 4,
          roleName: "Faculty",
        },
        {
          roleId: 8,
          roleName: "Demo Student",
        },
      ],
      session: [],
      AvailableLogins: -1,
      file: "",
      bulkUserUploadResult: [],
      taggingSelected: [],
      exceldata: [],
      pageName: "",
      requestPageName: "CreateUser",
      PersistedPageName: "",
      PersistedEditId: "",
      editUserId: "",
      editSelectedSubject: [],
      profileData: [],
      newSubjects: {
        subjectName: "",
        subjectId: "",
      },
      maxDate: "",
      reload: false,
      componentKey: 0,
    };
  },
  activated() {
    const user = this.$store.getters.user;
    this.OwnerName = user.UserName;
    if (this.$route.params.pageName) {
      this.requestPageName = this.$route.params.pageName;
    }
    this.reload = this.$route.params.reload;
    this.pageName = this.requestPageName;
    this.editUserId = Number(this.$route.params.EditId);
    API.getProfile((profile) => {
      this.profileData = profile;
      this.CreateUser.ExpiredOn = moment(String(this.profileData.affiliation.ExpiryDate))
        .utcOffset("-00:00")
        .format("yyyy-MM-DD");
      this.maxDate = moment(String(this.profileData.affiliation.ExpiryDate))
        .utcOffset("-00:00")
        .format("yyyy-MM-DD");
    });
    if (this.reload === true) {
      let token = "";
      this.$store.dispatch("showLoader", true);
      if (localStorage && localStorage.getItem("SLToken")) {
        token = localStorage.getItem("SLToken") || "";
      }
      const data = {
        serverToken: token,
      };

      Mobileapi.getLoginCredit(data, (response) => {
        if (response.responseCode === 400) {
          window.M.toast({
            html: `Error:${response.Message}`,
            classes: "rounded error",
          });
        } else if (response.responseCode === 200) {
          this.AvailableLogins = response.data.info.availableLogins;
          // this.AvailableLogins = -1;
        }
      });
      this.Resetcontrols();
      this.$store.dispatch("showLoader", false);
    }
    // const perfEntries = performance.getEntriesByType("navigation");
    if (this.requestPageName === "CreateUser") {
      this.Resetcontrols();
      // const user = this.$store.getters.user;
      let token = "";
      if (localStorage && localStorage.getItem("SLToken")) {
        token = localStorage.getItem("SLToken") || "";
      }
      const data = {
        serverToken: token,
      };

      Mobileapi.getLoginCredit(data, (response) => {
        if (response.responseCode === 400) {
          window.M.toast({
            html: `Error:${response.Message}`,
            classes: "rounded error",
          });
        } else if (response.responseCode === 200) {
          this.AvailableLogins = response.data.info.availableLogins;
          // this.AvailableLogins = -1;
        }
      });
      this.batch = [];
      this.$store.dispatch("showLoader", true);
      API.getCourses({}, (response) => {
        this.$store.dispatch("showLoader", false);
        this.course = response.data;
      });

      this.$store.dispatch("showLoader", true);
      API.getBatches(user.AffiliationId, (response) => {
        this.$store.dispatch("showLoader", false);
        this.batch = response.data;
      });
      this.CreateUser.OwnerUserId = user.UserId;
      Doubtapi.getSession({}, (responseResult) => {
        if (responseResult.statusCode === 400) {
          window.M.toast({
            html: `Error:${responseResult.data.errorMessage}`,
            classes: "rounded error",
          });
        } else if (responseResult.statusCode === 200) {
          this.sessions = responseResult.data;
          if (this.pageName === "User") {
            this.AvailableLogins = 1;
          }
        }
      });
    }
    if (this.requestPageName === "User") {
      this.ClearEditUserData();
      this.pageName = this.requestPageName;
      this.RefershLoad();
      this.AvailableLogins = 1;
    } else {
      this.Resetcontrols();
    }
    if (this.PersistedPageName === "User") {
      this.pageName = this.PersistedPageName;
      if (this.editUserId === undefined) {
        this.editUserId = this.PersistedEditId;
      }
    } else {
      this.Resetcontrols();
    }
    if (this.pageName === "User") {
      this.$store.dispatch("showLoader", true);
      Mobileapi.getUserById(this.editUserId, (response) => {
        this.$store.dispatch("showLoader", false);
        if (response.responseCode === 400) {
          window.M.toast({
            html: `Error:${response.Message}`,
            classes: "rounded error",
          });
        } else if (response.responseCode === 200) {
          this.CreateUser = response.data[0];
          this.$store.dispatch("showLoader", true);
          API.getCourses({}, (res) => {
            this.$store.dispatch("showLoader", false);
            this.course = res.data;
            this.$store.dispatch("showLoader", true);
            API.getBatches(user.AffiliationId, (batch) => {
              this.$store.dispatch("showLoader", false);
              this.batch = batch.data;
              this.$store.dispatch("showLoader", true);
              API.getSubjectsForCourses(response.data[0].CourseId, {}, (subject) => {
                this.$store.dispatch("showLoader", false);
                this.SelectedSubjectList = subject.data;

                this.CreateUser.CourseId = Number(response.data[0].CourseId);
                if (this.CreateUser.CourseId !== "") {
                  this.courseBatchList = this.batch.filter(
                    (batches) => batches.CourseId === Number(this.CreateUser.CourseId),
                  );
                }

                for (let i = 0; i < this.course.length; i += 1) {
                  if (this.course[i].courseId === this.CreateUser.CourseId) {
                    this.SelectedSubjectList = this.course[i].subjectList;
                    this.AffiliationCourseId = Number(this.course[i].affiliationCourseId);
                    break;
                  }
                }

                if (response.data[0].GenderId === 0) {
                  this.CreateUser.Gender = 1;
                } else {
                  this.CreateUser.Gender = response.data[0].GenderId;
                }
                if (response.data[0].DateOfBirth != null) {
                  this.CreateUser.DOB = moment(String(response.data[0].DateOfBirth))
                    .utcOffset("-00:00")
                    .format("yyyy-MM-DD");
                }
                if (response.data[0].ExpiredOn != null) {
                  this.CreateUser.ExpiredOn = moment(String(response.data[0].ExpiredOn))
                    .utcOffset("-00:00")
                    .format("yyyy-MM-DD");
                }
                this.subjectSelected = [];
                this.CreateUser.isActive = response.data[0].IsActive;
                if (this.CreateUser.IsThirdPartyTestEnabled === undefined) {
                  this.CreateUser.IsThirdPartyTestEnabled = true;
                } else {
                  this.CreateUser.IsThirdPartyTestEnabled =
                    response.data[0].IsThirdPartyTestEnabled;
                }
                this.CreateUser.BatchId = response.data[0].BatchId;
                const EditsubjectIds = response.data[0].AccessSubjectId;

                if (EditsubjectIds !== null || EditsubjectIds !== "") {
                  this.editSelectedSubject = response.data[0].AccessSubjectId.split(",").map((x) =>
                    Number(x),
                  );
                }
                for (let i = 0; i < this.editSelectedSubject.length; i += 1) {
                  for (let j = 0; j < this.SelectedSubjectList.length; j += 1) {
                    if (this.SelectedSubjectList[j].subjectId === this.editSelectedSubject[i]) {
                      this.newSubjects.subjectName = this.SelectedSubjectList[j].subjectName;
                      this.newSubjects.subjectId = this.SelectedSubjectList[j].subjectId;
                      const data = JSON.stringify(this.newSubjects);
                      this.subjectSelected[i] = JSON.parse(data);
                    }
                  }
                }
              });
            });
          });
        }
      });
    }
  },
  computed: {
    ShowCreateUser() {
      return this.AvailableLogins > 0;
    },
    calculateSuccessCount() {
      const successCount = this.bulkUserUploadResult.filter(
        (el) => el.addUserStatus === "Success",
        this,
      );
      return successCount.length;
    },
    calculateFailureCount() {
      const successCount = this.bulkUserUploadResult.filter(
        (el) => el.addUserStatus.includes("Failure"),
        this,
      );
      return successCount.length;
    },
  },
  methods: {
    RefershLoad() {
      localStorage.setItem("EditPage", "User");
      localStorage.setItem("EditId", this.editUserId);
    },
    ClearEditUserData() {
      localStorage.removeItem("EditPage");
      localStorage.removeItem("EditId");
    },

    GetAffiliationSampleReport() {
      Mobileapi.AffiliationSampleFile((response) => {
        // this.$store.dispatch("showLoader", false);
        this.exceldata = response;
        const blob = new Blob([Papa.unparse(this.exceldata)], {
          type: "text/csv;charset=utf-8;",
        });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "BulkAddUser.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    addTag(newTag) {
      const tag = {
        subjectName: newTag,
        subjectId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.SelectedSubjectList.push(tag);
      this.subjectSelected.push(tag);
    },
    selectNone() {
      this.subjectSelected = [];
      this.$refs.subjectSelected.deactivate();
    },
    selectAll() {
      this.subjectSelected = this.SelectedSubjectList;
      this.$refs.subjectSelected.deactivate();
    },
    onCourseChange(event) {
      this.CreateUser.CourseId = Number(event.target.value);
      this.subjectSelected = [];
      this.courseBatchList = this.batch.filter(
        (batch) => batch.CourseId === Number(event.target.value),
      );

      for (let i = 0; i < this.course.length; i += 1) {
        if (this.course[i].courseId === Number(event.target.value)) {
          this.SelectedSubjectList = this.course[i].subjectList;
          this.AffiliationCourseId = Number(this.course[i].affiliationCourseId);
          break;
        }
      }
    },

    onBulkUserCourseChange(event) {
      this.BulkUser.CourseId = Number(event.target.value);
      this.subjectSelected = [];
      this.courseBatchList = this.batch.filter(
        (batch) => batch.CourseId === Number(event.target.value),
      );

      for (let i = 0; i < this.course.length; i += 1) {
        if (this.course[i].courseId === Number(event.target.value)) {
          this.SelectedSubjectList = this.course[i].subjectList;
          this.AffiliationCourseId = Number(this.course[i].affiliationCourseId);
          break;
        }
      }
    },
    onBulkUserBatchChange(event) {
      this.BulkUser.CenterCodeId = Number(event.target.value);
    },
    onBatchChange(event) {
      this.CreateUser.CenterCodeId = Number(event.target.value);
    },

    fortmatResponse(res) {
      return JSON.stringify(res);
    },
    Result() {
      let isValidPage = true;
      if (this.CreateUser.Name === "") {
        this.wrongname = true;
        isValidPage = false;
      } else {
        this.wrongname = false;
      }
      if (this.CreateUser.EmailId === "") {
        this.wrongEmail = true;
        isValidPage = false;
      } else {
        this.wrongEmail = false;
      }
      if (this.CreateUser.CourseId === "") {
        this.wrongcourse = true;
        isValidPage = false;
      } else {
        this.wrongcourse = false;
      }
      if (this.CreateUser.CenterCodeId === "") {
        this.wrongbatch = true;
        isValidPage = false;
      } else {
        this.wrongbatch = false;
      }
      if (this.CreateUser.RoleId === "") {
        this.wrongrole = true;
        isValidPage = false;
      } else {
        this.wrongrole = false;
      }
      if (this.CreateUser.ExpiredOn === "") {
        this.accountexpiry = true;
        isValidPage = false;
      } else {
        this.accountexpiry = false;
      }
      if (this.AvailableLogins === 0) {
        this.noLogins = true;
        isValidPage = false;
      } else {
        this.noLogins = false;
      }
      return isValidPage;
    },
    Resetcontrols() {
      this.CreateUser.EmailId = "";
      this.CreateUser.Name = "";
      this.CreateUser.Mobile = "";
      this.CreateUser.DOB = "";
      this.CreateUser.CourseId = "";
      this.CreateUser.BatchId = "";
      this.CreateUser.AccessSubjectId = "";
      this.CreateUser.CenterCodeId = "";
      this.CreateUser.RoleId = 2;
      this.CreateUser.ExpiredOn = "";
      this.subjectSelected = [];
      this.taggingSelected = [];
    },
    createuser() {
      if (this.Result()) {
        const user = this.$store.getters.user;
        this.CreateUser.AffiliationCodeId = Number(user.AffiliationId);
        if (this.subjectSelected.length > 0) {
          this.subjectSelected.forEach((element) => {
            this.taggingSelected.push(element.subjectId);
          });
          if (this.taggingSelected.length > 0) {
            this.CreateUser.AccessSubjectId = this.taggingSelected.join();
          } else {
            this.CreateUser.AccessSubjectId = null;
          }
        }
        if (this.CreateUser.IsThirdPartyTestEnabled === "") {
          this.CreateUser.IsThirdPartyTestEnabled = false;
        }

        if (this.CreateUser.isActive === "") {
          this.CreateUser.isActive = false;
        }
        Doubtapi.createUser(this.CreateUser, (responseResult) => {
          if (responseResult.statusCode === 400) {
            window.M.toast({
              html: `Error:${responseResult.errorMessage}`,
              classes: "rounded error",
            });
            this.taggingSelected = [];
          } else if (responseResult.statusCode === 200) {
            this.sessions = responseResult.data;
            window.M.toast({
              html: `Success:${responseResult.data}`,
              classes: "rounded success",
            });
            this.Resetcontrols();
          }
        });
      } else {
        window.M.toast({
          html: "Please fill in the * Required Fields",
          classes: "rounded error",
        });
      }
    },
    updateuser() {
      if (this.Result()) {
        this.taggingSelected = [];
        const user = this.$store.getters.user;
        this.CreateUser.AffiliationCodeId = user.AffiliationId;
        if (this.subjectSelected.length > 0) {
          this.subjectSelected.forEach((element) => {
            this.taggingSelected.push(element.subjectId);
          });

          if (this.taggingSelected.length > 0) {
            this.CreateUser.AccessSubjectId = this.taggingSelected.join();
          } else {
            this.CreateUser.AccessSubjectId = null;
          }
        }
        if (
          this.CreateUser.IsThirdPartyTestEnabled === "" ||
          this.CreateUser.IsThirdPartyTestEnabled === null
        ) {
          this.CreateUser.IsThirdPartyTestEnabled = false;
        }

        if (this.CreateUser.isActive === "") {
          this.CreateUser.isActive = false;
        }

        Mobileapi.UpdateUser(this.CreateUser, (response) => {
          if (response.responseCode === 200) {
            window.M.toast({
              html: "Updated:User Details updated",
              classes: "rounded success",
            });
          } else if (response.responseCode === 400) {
            window.M.toast({
              html: `Error:${response.message}`,
              classes: "rounded error",
            });
          } else {
            window.M.toast({
              html: `Error:${response.data.info}`,
              classes: "rounded error",
            });
          }
        });
      } else {
        window.M.toast({
          html: "Please fill in the * Required Fields",
          classes: "rounded error",
        });
      }
    },
    isEmailValid() {
      const re = /(.+)@(.+){2,}\.(.+){2,}/;
      if (re.test(this.CreateUser.EmailId)) {
        this.wrongEmail = false;
      } else {
        this.wrongEmail = true;
      }
    },
    isNumberValid() {
      const re = /^[123456789]\d{9}$/;
      if (re.test(this.CreateUser.Mobile)) {
        this.wrongNumber = false;
      } else {
        this.wrongNumber = true;
      }
    },

    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    submitFile(event) {
      const user = this.$store.getters.user;
      this.BulkUser.BulkAffiliationCodeId = Number(user.AffiliationId);
      const fileField1 = document.getElementById("file1");
      $("#updProgress").removeClass("displayNone");
      $("#updProgress").addClass("displayBlock");
      const formData = new FormData();
      formData.append("bulkUserCsv", fileField1.files[0]);
      formData.append("bulkAffiliationCodeId", this.BulkUser.BulkAffiliationCodeId);
      formData.append("bulkCenterCodeId", this.BulkUser.BulkCenterCodeId);
      formData.append("bulkCourseId", this.BulkUser.BulkCourseId);
      formData.append("bulkRoleId", this.BulkUser.BulkRoleId);
      if (this.file !== "") {
        if (this.BulkUser.BulkCourseId !== "" && this.BulkUser.BulkCourseId !== "") {
          Doubtapi.bulkCreateUser(formData, (responseResult) => {
            if (responseResult.statusCode === 400) {
              window.M.toast({
                html: `Error:${responseResult.errorMessage}`,
                classes: "rounded error",
              });
            } else if (responseResult.statusCode === 200) {
              this.bulkUserUploadResult = responseResult.data;
            }
          });
        } else {
          window.M.toast({
            html: "Please Select Course & Batch for Creating Bulk Users",
            classes: "rounded error",
          });
        }
      } else {
        window.M.toast({
          html: "Please Upload the file",
          classes: "rounded error",
        });
      }

      event.preventDefault();
    },
  },
};
</script>
<style scoped>
.Aff-TestAnalysisReport-Header {
  margin-top: 19px;
  font-size: 20px;
}
.backbutton {
  width: 144px;
  /* height: 36px; */
  /* margin-top: 7px; */
  font-size: 12px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}
table.qtl {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.error {
  background-color: indianred;
  color: white;
}

.success {
  background-color: green;
  color: white;
}

table.qtl thead tr {
  background-color: #6a2f85;
  color: #fff;
}

table.qtl thead tr td:first-child {
  border-radius: 5px 0 0 5px;
}

table.qtl thead tr th {
  border-radius: 0px;
}

table.qtl thead tr td {
  border-radius: 0;
}

.text-container {
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.dtitle {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
}

.dropdown {
  border-color: rgb(222, 226, 230);
}

.dbtn {
  width: 144px;
  height: 36px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.dropd {
  border-radius: 5px;
  width: 268px;
  height: 36px;
}

.dropdDate {
  width: 268px;
  height: 36px;
}

.drop {
  height: 30px;
  border-color: rgb(222, 226, 230);
}

.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.checkbox {
  width: 20px;
  height: 20px;
}

#toast-container {
  z-index: 100000;
}
.multiselect__tags {
  border: 1px solid #ccc;
  width: 268px;
}
.multiselect__select {
  right: -15px !important;
  width: 90px !important;
}
.dropdDate {
  border: 1px solid #ccc;
  border-radius: 5px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
